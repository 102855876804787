<template>
  <transition name="fade">
    <div class="modal">
      <div aria-hidden="true" class="backdrop"></div>

      <div class="content">

        <header v-if="title" class="head">
          <p class="title">{{ title }}</p>
          <a v-if="closeButton" aria-label="close" class="delete" @click="closeButton">
            <font-awesome-icon :icon="['fa', 'xmark']"/>
          </a>
        </header>
        <header v-else :class="$style.headerIcon"
                :style="`background: url(${modalBg}); background-size: cover`"
                class="head">
          <img v-if="headerIcon" :class="$style.headerImage" :src="$data[headerIcon]" alt="icon"/>
        </header>

        <section class="body">
          <slot name="body">
            body parts
          </slot>
        </section>
        <footer class="footer flex gap-4">
          <slot name="footer">
            foot in the mouth
          </slot>
        </footer>
      </div>
    </div>
  </transition>
</template>

<script>

import modalBg from '@/assets/backgrounds/icons.png';
import starsIcons from '@/assets/backgrounds/stars-n-coins.png';
import videoIcon from '@/assets/icons/video.png';

export default {
  data() {
    return {
      modalBg,
      starsIcons,
      videoIcon,
    }
  },
  name: "ModalBox",
  props: {
    title: {
      Type: String,
    },
    headerIcon: {
      type: String,
    },
    closeButton: {
      type: Function,
    }
  },
  methods: {},
}
</script>

<style lang="scss" module>
.headerIcon {
  height: 113px;
}

.headerImage {
  position: relative;
  height: 130px;
  top: -16px;
  margin-left: auto;
  margin-right: auto;
}
</style>
