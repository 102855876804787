<template>
  <div class="container mx-auto pb-safe">
    <router-view v-slot="{ Component, route }">
      <component :is="Component" :key="route.path"/>
    </router-view>
    <div style="flex-grow:1"></div>
  </div>
</template>

<script>
import SocketIoService from './services/socketio.service.js';

export default {
  name: 'App',
  components: {},
  created() {
    let urlParams = new URLSearchParams(window.location.search);
    let osPlayerId = urlParams.get('osPlayerId');
    SocketIoService.setupSocketConnection(osPlayerId);
  },
  beforeUnmount() {
    SocketIoService.disconnect();
  },
  computed: {},
  methods: {}
}

window.refresh = function () {
  SocketIoService.emit('player', { cmd: 'get' });
}
</script>

<style lang="scss" module>

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

</style>
