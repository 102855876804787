import { io } from 'socket.io-client';
import store from '../store';
import router from '../router';
import { create as timeSync } from 'timesync';

class SocketIoService {

  constructor() {
    this.socket = null;
    this.lastListUpdate = null; // we don't want flashing stuff if too much activity
    this.listUpdateDelay = 2500;
    this.queue = [];
  }

  setupSocketConnection(osPlayerId) {

    console.log(`[socket.io] connecting, osPlayerId: ${osPlayerId}`);

    this.socket = io(store.getters.getIoServer, {
      transports: ["polling", "websocket"],
      upgrade: true
    });
    
    const ts = timeSync({
      server: this.socket,
    });

    store.commit('serverTime', ts);

    ts.send = function (socket, data, timeout) {
      return new Promise(function (resolve, reject) {
        const timeoutFn = setTimeout(reject, timeout);

        socket.emit('timesync', data, function () {
          clearTimeout(timeoutFn);
          resolve();
        });
      });
    };

    ts.on('change', function (offset) {
      console.log('[timesync] offset: ' + Math.round(offset * 1000) / 1000 + ' ms');
    });

    this.socket.on('timesync', function (data) {
      ts.receive(undefined, data);
    });

    this.socket.on("connect_error", (err) => {
      console.log(`connect_error due to ${err.message}`);
    });

    this.socket.on('player', (msg) => {
      console.log('[socket.io] recv player', msg);
      switch (msg.cmd) {
        case 'get': {
          store.commit('player', msg.result);
          store.commit('connectionStatus', 'connected');
          if (this.queue.length) {
            for (let i in this.queue) {
              this.emit(this.queue[i].cmd, this.queue[i].msg);
            }
            this.queue = [];
          }
          break;
        }

        case 'leaderboard': {
          store.commit('leaderboard', msg.result);
          break;
        }

        case 'activeTournament': {
          store.commit('activeTournament', msg.result);
          break;
        }

        case 'coinChange': {
          store.commit('coinChange', msg.result);
          break;
        }
      }
    });

    this.socket.on('tournament', (msg) => {
      console.log('[socket.io] recv tournament', msg);

      switch (msg.cmd) {
        case 'list': {
          this.listUpdate = msg.result;

          if (!this.lastListUpdate || (new Date() - this.lastListUpdate >= this.listUpdateDelay)) {
            clearTimeout(this.updateListTimer);
            store.commit('tournaments', this.listUpdate);
            this.lastListUpdate = new Date();
          } else {
            this.updateListTimer = setTimeout(() => {
              store.commit('tournaments', this.listUpdate);
              this.lastListUpdate = new Date();
            }, this.listUpdateDelay);
          }
          break;
        }

        case 'history': {
          store.commit('tournamentsHistory', msg.result);
          break;
        }

        case 'get': {
          store.commit('tournament', msg.result);
          break;
        }

        case 'register': {
          store.commit('tournamentRegister', msg.result);
          break;
        }

        case 'unregister': {
          store.commit('tournamentRegister', msg.result);
          break;
        }
      }
    });

    this.socket.on('connect', () => {
      store.commit('connectionStatus', 'connecting');
      console.log('[socket.io] connected sid:[' + this.socket.id + ']');

      if (store.getters.getToken) {
        this.socket.emit('player',
          { cmd: 'sign-in', token: store.getters.getToken || null, osPlayerId: osPlayerId });
      } else {
        store.commit('connectionStatus', 'connected');
        if (this.queue.length) {
          for (let i in this.queue) {
            this.emit(this.queue[i].cmd, this.queue[i].msg);
          }
          this.queue = [];
        }
      }
    });

    this.socket.on('disconnect', async () => {
      store.commit('player', null);
      store.commit('connectionStatus', 'connecting');
    });

    this.socket.io.on("reconnect", () => {
      console.log('[socket.io] reconnected');

      // on connect we don't need this, cause if we're on that page we're already sending that message
      if (
        router.currentRoute.value.name === 'tournament-info' &&
        router.currentRoute.value.params &&
        router.currentRoute.value.params.id
      ) {
        this.emit('tournament', { cmd: 'subscribe', id: router.currentRoute.value.params.id });
      }
    });
  }

  disconnect() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  emit(ev, msg) {
    if (store.getters.getConnectionStatus === 'connected') {
      console.log('[socket.io] send', ev, msg);
      this.socket.emit(ev, msg);
    } else {
      // we don't want to queue a bunch of times the same message if we're having trouble connecting
      this.queue.push({ cmd: ev, msg: msg });
      console.log('[socket.io] queued', ev, msg);
    }
  }
}

export default new SocketIoService();