<template>
  <div>
    <user-header/>
    <div class="tabs">
      <a :class="{ 'is-active': tournamentType === 'list' }" class="tab"
         href="#list">Regular</a>
      <a :class="{ 'is-active': tournamentType === 'active' }" class="tab" href="#active">Active
        <span class="font-bold">{{ activeCount }}</span></a>
      <a :class="{ 'is-active': tournamentType === 'history' }" class="tab"
         href="#history">Recent</a>
    </div>
    <div v-if="tournamentType === 'history'" class="toggles p-4">
      <div :class="{ 'is-active': filterMode === 'all' }" class="toggle"
           @click="filterMode = 'all'">All
      </div>
      <div :class="{ 'is-active': filterMode === 'personal' }" class="toggle"
           @click="filterMode = 'personal'">Own
      </div>
    </div>
    <div v-else class="toggles p-4">
      <div :class="{ 'is-active': filterMode === 'all' }" class="toggle"
           @click="filterMode = 'all'">Any
      </div>
      <div :class="{ 'is-active': filterMode === 'regular' }" class="toggle"
           @click="filterMode = 'regular'"><img :alt="$data['regularText']" :src="$data['regular']">
      </div>
      <div :class="{ 'is-active': filterMode === 'duel' }" class="toggle"
           @click="filterMode = 'duel'"><img :alt="$data['duelText']" :src="$data['duel']">
      </div>
      <div :class="{ 'is-active': filterMode === 'mirror' }" class="toggle"
           @click="filterMode = 'mirror'"><img :alt="$data['mirrorText']" :src="$data['mirror']">
      </div>
      <div :class="{ 'is-active': filterMode === 'tripleDraft' }" class="toggle"
           @click="filterMode = 'tripleDraft'"><img :alt="$data['draftText']"
                                                    :src="$data['tripleDraft']"></div>
      <div :class="{ 'is-active': filterMode === 'tripleElixir' }" class="toggle"
           @click="filterMode = 'tripleElixir'"><img :alt="$data['tripleElixirText']"
                                                     :src="$data['tripleElixir']"></div>
    </div>
    <div v-if="sortedTournaments && sortedTournaments.length">
      <div v-for="tournament in sortedTournaments" v-bind:key="tournament.id">
        <tournament-list-row :tournament="tournament"></tournament-list-row>
      </div>
    </div>
    <div v-else-if="sortedTournaments == null">
      <div v-for="index in [0,1,2,3,4]"
           v-bind:key="index" class="flex hover:bg-neutral-100 border-b border-neutral-200 py-3">
        <div class="flex-none pr-4 pl-4">
          <div class="gameMode-icon skeleton"></div>
        </div>
        <div class="flex-1">
          <div class="block skeleton w-1/2"></div>
          <div class="block skeleton w-2/3"></div>
        </div>
        <div class="flex-1 pr-4">
          <div class="grid place-items-end">
            <div class="skeleton w-1/5"></div>
            <div class="skeleton w-1/3"></div>
          </div>
        </div>
      </div>
    </div>
    <div v-else class="text-center pt-6 m-4 text-base">
      <img :src="$data['dummy']" alt="icon" class="inline-block h-36">
      <p class="m-4 text-base">
        <template v-if="tournamentType === 'history'">
          Join a tournament
        </template>
        <template v-else-if="tournamentType === 'active'">
          No active tournaments found
        </template>
      </p>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import icons from '../../mixins/icons.js';
import SocketIoService from "@/services/socketio.service";
import UserHeader from '@/components/global/UserHeader.vue';
import TournamentListRow from "@/components/tournaments/TournamentListRow";

export default {
  data() {
    return {
      filterMode: 'all',
      tournamentType: 'list',
    }
  },
  name: "TournamentList.vue",
  mixins: [
    icons,
  ],
  computed: {
    ...mapGetters({
      tournaments: 'getTournaments',
      tournamentsHistory: 'getTournamentsHistory',
      myActiveTournament: 'getActiveTournament',
      myTag: 'getTag',
    }),
    activeCount: function () {
      if (!this.tournaments) {
        return 0;
      }

      return this.tournaments.filter(tournament => tournament.status === 'started').length;
    },
    sortedTournaments: function () {
      let sortedTournaments;

      if (this.tournamentType === 'history') {
        if (!this.tournamentsHistory) {
          return null;
        }

        if (this.filterMode === 'personal') {
          sortedTournaments = this.tournamentsHistory.filter(tournament => tournament.players && tournament.players[this.myTag]);
        } else {
          sortedTournaments = this.tournamentsHistory;
        }

        return sortedTournaments.sort((a, b) => {
          if ((this.myActiveTournament || 0) === a.id) return -1;
          if ((this.myActiveTournament || 0) === b.id) return 1;

          return a.updatedAt._seconds > b.updatedAt._seconds ? -1 : 1;
        });
      }

      if (!this.tournaments) {
        return null;
      }

      switch (this.tournamentType) {
        case 'list': {
          sortedTournaments = this.tournaments.filter(tournament => tournament.status === 'registering');
          break;
        }
        case 'active': {
          sortedTournaments = this.tournaments.filter(tournament => tournament.status === 'started');
          break;
        }
      }

      sortedTournaments = sortedTournaments.sort((a, b) => {
        // tournament I joined appears first
        if ((this.myActiveTournament || 0) === a.id) return -1;
        if ((this.myActiveTournament || 0) === b.id) return 1;

        // tournaments with players
        if (a.playerCount !== b.playerCount) return (a.playerCount < b.playerCount ? 1 : -1);

        // special events
        if (a.eventId && !b.eventId) {
          return -1;
        } else if (!a.eventId && b.eventId) {
          return 1;
        } else if (a.eventId && b.eventId) {
          return a.registrationAt < b.registrationAt ? 1 : -1;
        }

        // sort by mode
        if (a.mode !== b.mode) return a.mode > b.mode ? -1 : 1;

        // lastly, tournament size
        return a.playerLimit < b.playerLimit ? -1 : 1;

      });

      // hide stuff where registration starts in more than 2 days, this will prevent
      // karaoke nite and skeleton brunch from appearing at the same time
      sortedTournaments = sortedTournaments.filter(tournament => {
        if (!tournament.registrationAt) return true;

        return ((tournament.registrationAt._seconds - new Date().getTime() / 1000) < 3600 * 186) // todo: 36;
      });

      return sortedTournaments.filter(tournament => {
        return (this.filterMode === 'all' || tournament.mode === this.filterMode)
      });
    }
  },
  components: {
    UserHeader,
    TournamentListRow,
  },
  methods: {
    updateTournamentType(hash) {
      switch (hash) {
        case '#active': {
          this.tournamentType = 'active';
          SocketIoService.emit('tournament', { cmd: 'list' });
          break;
        }
        case '#history': {
          this.tournamentType = 'history';
          SocketIoService.emit('tournament', { cmd: 'history' });
          break;
        }
        default: {
          this.tournamentType = 'list';
          SocketIoService.emit('tournament', { cmd: 'list' });
        }
      }
    }
  },
  mounted() {
    this.updateTournamentType(this.$route.hash);
  },
  watch: {
    $route(to, from) {
      // if we're staying on this page and changing hash, then let's handle this
      if (to.path === from.path && to.hash !== from.hash) {
        this.updateTournamentType(to.hash);
      }
    },
  }
}
</script>

<style lang="scss" module>
</style>