<template>
  <div
      :class="{
        'bg-green-50': battleInfo[player] && battleInfo[player] === myTag,
       }"
      class="player-info">
    <div :class="{
      'opacity-40': (!battleInfo.winner && battleInfo.match && tournament.battles[battleInfo.match] &&
                        tournament.battles[battleInfo.match].invites[battleInfo[player]] === 'noResponse'),
      'opacity-40 text-red-500': (!battleInfo.winner && battleInfo.match && tournament.battles[battleInfo.match] &&
                        tournament.battles[battleInfo.match].invites[battleInfo[player]] === 'declined'),
      'font-bold': (battleInfo.winner && battleInfo.winner === battleInfo[player]),
          }"
         class="bracket-battle-info">
      <template v-if="battleInfo[player]">
        <div
            :class="(battleInfo.winner && battleInfo.winner === battleInfo[player]) ? 'font-bold' : ''"
            class="block pb-1">
          {{ tournament.players[battleInfo[player]].name }}
        </div>
        <div class="text-xs text-neutral-700 h-4 l-4">
          <p v-if="tournament.players[battleInfo[player]].clan && tournament.players[battleInfo[player]].clan.name">
            <img
                :src="'https://cdn.statsroyale.com/images/badges/'+ tournament.players[battleInfo[player]].clan.badgeId +'.png'"
                alt="clan icon" class="inline h-4 align-middle">
            {{ tournament.players[battleInfo[player]].clan.name }}
          </p>
          <p v-else>
            <img :src="'https://cdn.statsroyale.com/images/badges/0.png'"
                 alt="clan icon" class="inline h-4 align-middle">
            No Clan
          </p>
        </div>
      </template>
      <template v-else>
        <div class="block opacity-50">
          BYE
        </div>
      </template>
    </div>
    <div
        :class="{
          'opacity-40': (!battleInfo.winner && battleInfo.match && tournament.battles[battleInfo.match] &&
                        tournament.battles[battleInfo.match].invites[battleInfo[player]] === 'noResponse'),
          'opacity-40 text-red-500': (!battleInfo.winner && battleInfo.match && tournament.battles[battleInfo.match] &&
                        tournament.battles[battleInfo.match].invites[battleInfo[player]] === 'declined'),
          'font-bold': (battleInfo.winner && battleInfo.winner === battleInfo[player]),
        }"
        class="flex-none w-16 text-lg text-right">
      <template v-if="battleInfo.winner">
        <div v-if="battleInfo.match"
             :title="tournament.battles[battleInfo.match].invites[battleInfo[player]]">
          <template
              v-if="tournament.battles[battleInfo.match].crowns && tournament.battles[battleInfo.match].crowns[battleInfo[player]]">
            {{ tournament.battles[battleInfo.match].crowns[battleInfo[player]] }}
          </template>
          <template v-else-if="battleInfo.winner === battleInfo[player]">
            3
          </template>
          <template v-else>
            0
          </template>
          <p v-if="tournament.players[battleInfo[player]].prize && lastPlayerRound"
             class="text-sm whitespace-nowrap font-normal text-neutral-700">
            <img :src="$data['coin']" alt="coins" class="coin-icon small">
            {{
              Number(tournament.players[battleInfo[player]].prize)
              .toLocaleString()
            }}
          </p>
        </div>
      </template>
      <div v-else-if="battleInfo.match" class="relative">
        <template
            v-if="tournament.battles[battleInfo.match].state === 'inProgress'">
          <a :href="`clashroyale://specHostedMatch?match_tag=` +
                            battleInfo.match.split('#')[1] + `&player_tag=` + battleInfo[player].split('#')[1]">
            <img :src="$data['spectate']" alt="spectate"
                 class="svg-inline--fa spectate-icon-bracket">
          </a>
        </template>
        <template v-else-if="statusClass && statusIcon">
          <font-awesome-icon
              :class="statusClass"
              :icon="statusIcon"
              fixed-width size="lg"/>
        </template>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import icons from '../../mixins/icons.js';

export default {
  name: "TournamentBracketPlayer",
  props: {
    tournament: {
      Type: Object,
    },
    battleInfo: {
      Type: Object,
    },
    player: {
      Type: String,
    },
    lastPlayerRound: {
      Type: Number,
    },
    statusIcon: {
      Type: String,
    },
    statusClass: {
      Type: String,
    },
  },
  mixins: [
    icons,
  ],
  computed: {
    ...mapGetters({
      myTag: 'getTag',
    }),
  },
}
</script>
