import { createApp, h, configureCompat } from 'vue';

import App from './App.vue';
import router from './router';
import store from './store';

import { library } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faArrowRightToBracket } from '@fortawesome/free-solid-svg-icons';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { faSquareCheck } from '@fortawesome/free-solid-svg-icons';
import { faSquareXmark } from '@fortawesome/free-solid-svg-icons';
import { faToggleOn, faToggleOff } from '@fortawesome/free-solid-svg-icons';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import { faDiscord } from '@fortawesome/free-brands-svg-icons';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { faList } from '@fortawesome/free-solid-svg-icons';
import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faTrophy } from '@fortawesome/free-solid-svg-icons';
import { faUserXmark } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import VueConfetti from 'vue-confetti';
import VueClickAway from "vue3-click-away";
import VueAwesomeSwiper from 'vue-awesome-swiper';

import 'swiper/css';
import '@/assets/main.scss'; // tailwind css

const app = createApp({
  render: () => h(App),
})
.component("font-awesome-icon", FontAwesomeIcon)
.use(store)
.use(router)
.use(VueAwesomeSwiper)
.use(VueClickAway)
.use(VueConfetti);

library.add(faArrowRightFromBracket);
library.add(faArrowRightToBracket);
library.add(faCircle);
library.add(faSquareCheck);
library.add(faSquareXmark);
library.add(faChevronLeft);
library.add(faChevronDown);
library.add(faCopy);
library.add(faDiscord);
library.add(faEllipsisVertical);
library.add(faList);
library.add(faSpinner);
library.add(faToggleOff);
library.add(faToggleOn);
library.add(faTrophy);
library.add(faUserXmark);
library.add(faXmark);

configureCompat({
  WATCH_ARRAY: false,
  RENDER_FUNCTION: false,
});

app.mount('#app');