<template>
  <div>
    <div class="header is-small">
      <div class="flex">
        <div class="flex-none w-10">
          <div class="cursor-pointer p-1" @click="goBack()">
            <font-awesome-icon :icon="['fa', 'chevron-left']" size="lg"/>
          </div>
        </div>

        <div class="grow-1">
          <div class="title">Leaderboard</div>
        </div>
      </div>
    </div>

    <template v-if="orderedPlayers.length">
      <div v-for="(player, index) in orderedPlayers" v-bind:key="index"
           class="flex border-b border-neutral-200 py-2 gap-4 hover:bg-neutral-100 px-4"
           v-bind:class="{ 'has-background-success-light': myTag === player.id }">
        <div class="flex-none w-16 pt-1">
          <div :class="'rank-' + index" class="tag is-rank">{{ index + 1 }}</div>
          <a v-if="player.match" :href="`clashroyale://specHostedMatch?match_tag=` +
        player.match.split('#')[1] +
        `&player_tag=` +
        player.id.split('#')[1]">
            <img :src="$data['spectate']" alt="spectate" class="spectate-icon">
          </a>
          <span v-else>
        <img :src="$data['spectate']" alt="no spectate" class="spectate-icon-off">
      </span>
        </div>
        <div class="flex-1">
          <div class="block font-bold text-lg">
            <a :href="`statsroyale://profile/${player.id.replace('#','')}`" class="text-black"
               target="_blank">{{ player.name }}</a>
          </div>
          <p v-if="player.clan && player.clan.name" class="font-sm text-neutral-400">
            <img :src="'https://cdn.statsroyale.com/images/badges/'+ player.clan.badgeId +'.png'"
                 alt="clan icon" class="inline h-5"> {{ player.clan.name }}
          </p>
          <p v-else class="font-sm text-neutral-400">
            <img :src="'https://cdn.statsroyale.com/images/badges/0.png'" alt="clan icon"
                 class="inline h-5"> No Clan
          </p>
        </div>
        <div class="text-right">
          <p v-if="player.coins" class="text-lg whitespace-nowrap">
            <img :src="$data['coin']" alt="coins" class="coin-icon"> {{
              Number(player.coins)
              .toLocaleString()
            }}
          </p>
        </div>
      </div>
    </template>

    <div v-for="index in [0,1,2,3,4]" v-else v-bind:key="index"
         class="flex border-b border-neutral-200 py-2 gap-4 even:bg-neutral-100 px-4">
      <div class="w-10 animate-pulse">
        <div class="tag"></div>
      </div>
      <div class="grow">
        <div class="skeleton w-3/4"></div>
        <div class="skeleton w-1/3"></div>
      </div>
      <div class="w-1/6 text-right">
        <div class="skeleton w-full pulse"></div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import icons from '../../mixins/icons.js';
import SocketIoService from "@/services/socketio.service";

export default {
  name: "TournamentLeaderboard.vue",
  mixins: [
    icons,
  ],
  data() {
    return {
      lastPath: null,
    }
  },
  computed: {
    ...mapGetters({
      leaderboard: 'getLeaderboard',
      myTag: 'getTag',
    }),
    orderedPlayers: function () {
      if (this.leaderboard) {
        return this.leaderboard;
      } else {
        return [];
      }
    }
  },
  created: function () {
    this.lastPath = this.$router.options.history.state.back;
    SocketIoService.emit('player', { cmd: 'leaderboard' });
  },
  components: {},
  methods: {
    goBack() {
      if (this.lastPath) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
  }
}
</script>

<style lang="scss" module>
</style>
