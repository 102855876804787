<!--suppress ALL -->
<template>
  <div
      :style="`background: url(https://cdn.statsroyale.com/tournaments/crl_kneeling.png) 50% 50%; background-size: cover`"
      class="h-full w-full absolute">
    <div class="p-4 text-white">
      <div class="flex">
        <div class="flex-none w-10">
          <div class="cursor-pointer p-1" @click="$router.go(-1)">
            <font-awesome-icon :icon="['fa', 'chevron-left']" size="lg"/>
          </div>
        </div>

        <div class="grow-1 w-full">
          <p class="font-semibold text-xl pt-[0.1rem]">Congratulations! You won the tournament!</p>
          <img :src="$data['coin']" alt="coin" class="coin-icon"/><span
            class="text-lg">+{{ $route.query.prize }}</span>
        </div>
      </div>
    </div>
  </div>

</template>

<script>

import icons from '../../mixins/icons.js';

export default {
  mounted() {
    this.$confetti.start({
      particles: [
        {
          type: 'rect',
          dropRate: 5,
          colors: [
            '#FF9999',
            '#DD4444',
            '#9999FF',
            '#4444DD'
          ]
        }
      ],
      windSpeedMax: 1,
      particlesPerFrame: 1
    });
  },
  beforeRouteLeave(to, from, next) {
    this.$confetti.remove();
    next();
  },
  data() {
  },
  mixins: [
    icons
  ],
  name: "TournamentWinner.vue",
}
</script>

<style lang="scss" module>
</style>