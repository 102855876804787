<template>
  <div class="relative">
    <div class="absolute w-full z-40">
      <div id="bracket-fractions" class="w-full text-center z-40">
        dot dot dot
      </div>
    </div>
    <swiper :autoHeight="false"
            :breakpoints="{
                  640: { slidesPerView: ( showRounds > 1 ? 2.02 : 1.04 ) },
                  1024: { slidesPerView: ( showRounds > 1 ? 3.01 : 1.02) }
                }"
            :centeredSlides="false"
            :initialSlide="showRounds - fakeRounds"
            :modules="modules"
            :navigation="{ enabled: false, prevEl: '#bracket-prev', nextEl: '#bracket-next' }"
            :pagination="{ type: 'bullets', el: '#bracket-fractions', clickable: true }"
            :simulateTouch="true"
            :slides-per-view="( showRounds > 1 ? 1.1 : 1.05 )"
            :spaceBetween="0">
      <swiper-slide v-for="roundIndex in showRounds" v-bind:key="roundIndex"
                    :class="`round-${roundIndex}`"
                    class="swiper-bracket"
                    data-carousel-item>
        <!-- wrap all battles -->
        <div
            :class="{
                  'round-first': roundIndex === 1,
                  'round-last': roundIndex === showRounds,
                  'round-mid': roundIndex !== 1 && roundIndex !== showRounds
                }"
            class="bracket-round">
          <!-- battle container -->
          <template v-if="roundIndex > showRounds - fakeRounds">
            <div
                v-for="battleIndex in getBattleCount(showRounds, roundIndex)"
                v-bind:key="battleIndex"
                class="bracket-battle">
              <div class="text-xs text-neutral-600 uppercase pt-4">
                Round {{ roundIndex }} &middot; Battle #{{ parseInt(battleIndex) }}
              </div>
              <!-- Player A -->
              <template
                  v-if="tournament.rounds && tournament.rounds[roundIndex-1] && tournament.rounds[roundIndex-1][battleIndex / 2]">
                Waiting...
              </template>
              <div v-else class="player-info">
                <div class="bracket-battle-info text-neutral-500">
                  TBD
                </div>
              </div>
              <div :class="{ 'bracket-link': roundIndex < showRounds }"></div>
              <div class="player-info">
                <div class="bracket-battle-info text-neutral-500">
                  TBD
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div v-for="(battleInfo, battleIndex) in tournament.rounds[roundIndex-1]"
                 v-bind:key="battleIndex"
                 class="bracket-battle">
              <div class="text-xs text-neutral-600 uppercase pt-4">
                Round {{ roundIndex }} &middot; Battle #{{ (parseInt(battleIndex) + 1) }}
              </div>
              <TournamentBracketPlayer :battleInfo="battleInfo"
                                       :lastPlayerRound="lastPlayerRound[battleInfo.playerA] === roundIndex"
                                       :statusClass="battleInfo.match ? getMatchStatusIcon(tournament.battles[battleInfo.match].invites[battleInfo.playerA], 'class') : ''"
                                       :statusIcon="battleInfo.match ? getMatchStatusIcon(tournament.battles[battleInfo.match].invites[battleInfo.playerA], 'icon') : ''"
                                       :tournament="tournament"

                                       player="playerA">

              </TournamentBracketPlayer>
              <div :class="{ 'bracket-link': tournament.rounds[roundIndex] }"></div>
              <TournamentBracketPlayer :battleInfo="battleInfo"
                                       :lastPlayerRound="lastPlayerRound[battleInfo.playerB] === roundIndex"
                                       :statusClass="battleInfo.match ? getMatchStatusIcon(tournament.battles[battleInfo.match].invites[battleInfo.playerB], 'class') : ''"
                                       :statusIcon="battleInfo.match ? getMatchStatusIcon(tournament.battles[battleInfo.match].invites[battleInfo.playerB], 'icon') : ''"
                                       :tournament="tournament"
                                       player="playerB">
              </TournamentBracketPlayer>
            </div>
          </template>
        </div>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import icons from '../../mixins/icons.js';
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination } from 'swiper';
import TournamentBracketPlayer from './TournamentBracketPlayer'

export default {
  name: "TournamentBracket",
  props: {
    tournament: {
      Type: Object,
    },
  },
  data() {
    return {
      modules: [Navigation, Pagination],
      fakeRounds: 0,
    }
  },
  mixins: [
    icons,
  ],
  components: {
    Swiper,
    SwiperSlide,
    TournamentBracketPlayer,
  },
  computed: {
    ...mapGetters({
      myTag: 'getTag',
    }),
    lastPlayerRound: function () {
      if (!this.tournament || !this.tournament.rounds || !this.tournament.players) {
        return {};
      }

      let players = {};

      for (let roundIndex in this.tournament.rounds) {
        for (let battleIndex in this.tournament.rounds[roundIndex]) {
          if (this.tournament.rounds[roundIndex][battleIndex].playerA && this.tournament.rounds[roundIndex][battleIndex].playerB) {
            players[this.tournament.rounds[roundIndex][battleIndex].playerA] = parseInt(roundIndex) + 1;
            players[this.tournament.rounds[roundIndex][battleIndex].playerB] = parseInt(roundIndex) + 1;
          }
        }
      }

      return players;
    },
    showRounds: function () {
      if (!this.tournament) {
        return 0;
      }

      if (!this.tournament.rounds) {
        this.updateFakeRounds(Math.floor(Math.log(this.tournament.playerLimit) / Math.log(2)));
        return this.fakeRounds;
      }

      this.updateFakeRounds(0);

      if (this.tournament.status === 'completed') {
        return Object.keys(this.tournament.rounds).length - 1;
      } else {
        let lastRound = null;
        for (let iRound in this.tournament.rounds) {
          lastRound = iRound;
        }

        if (Object.keys(this.tournament.rounds[lastRound]).length > 1) {
          this.updateFakeRounds(Object.keys(this.tournament.rounds[lastRound]).length / 2);
        }
        return Object.keys(this.tournament.rounds).length + this.fakeRounds;
      }
    },
    playerSeeds: function () {
      if (!this.tournament || !this.tournament.rounds) {
        return null;
      }

      let playerSeeds = {};
      let currentIndex = 0;
      for (let seed in this.tournament.rounds[0]) {
        currentIndex++;
        playerSeeds[this.tournament.rounds[0][seed].playerA] = currentIndex;
        currentIndex++;
        playerSeeds[this.tournament.rounds[0][seed].playerB] = currentIndex;
      }

      return playerSeeds;
    },
  },
  mounted() {
  },
  beforeUnmount() {
  },
  methods: {
    updateFakeRounds: function (rounds) {
      this.fakeRounds = rounds;
    },
    nextPowerOfTwo: function (v) {
      v--;
      v |= v >> 1;
      v |= v >> 2;
      v |= v >> 4;
      v |= v >> 8;
      v |= v >> 16;
      v++;
      return v;
    },
    getBattleCount: function (totalRounds, currentRound) {
      let totalPlayers = this.nextPowerOfTwo(this.tournament.playerCount);
      for (let i = 0; i < currentRound; i++) {
        totalPlayers /= 2;
      }
      return totalPlayers;
    },
    getMatchStatusIcon: function (status, type) {
      switch (type) {
        case 'icon': {
          switch (status) {
            case 'accepted':
              return ['fa', 'square-check'];
            case 'noResponse':
              return ['fa', 'spinner'];
            case 'declined':
              return ['fa', 'square-xmark'];
          }
          break;
        }

        case 'class': {
          switch (status) {
            case 'accepted':
              return 'text-green-500';
            case 'noResponse':
              return 'text-neutral-500 fa-spin-pulse';
            case 'declined':
              return 'text-red-600';
          }
          break;
        }
      }
    },
  }
}
</script>
