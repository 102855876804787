<template>
  <span>{{ formatted }}</span>
</template>

<script>

export default {
  name: "ShortTime",
  data() {
    return {
      updateTimer: null,
      formatted: null,
      newChange: 0,
      formats: {
        seconds: {
          short: 's',
          long: ' sec',
        },
        minutes: {
          short: 'm',
          long: ' min',
        },
        hours: {
          short: 'h',
          long: ' hr',
        },
        days: {
          short: 'd',
          long: ' day',
        }
      }
    }
  },
  props: {
    datetime: {
      Type: Date,
      required: false,
    },
    seconds: {
      Type: Number,
      required: false,
    },
    format: {
      Type: ['short', 'long'],
      required: false,
      default: 'long',
    },
    suffix: {
      Type: Boolean,
      required: false,
      default: true,
    },
    autoUpdate: {
      Type: Number,
      required: false,
      default: 1000,
    },
    change: {
      Type: Number,
      required: false,
      default: 0,
    }
  },
  methods: {
    updateTime() {
      let secondsLeft;

      if (typeof this.datetime !== 'undefined') {
        secondsLeft = Math.floor((this.datetime.getTime() - new Date().getTime()) / 1000);
      } else if (typeof this.seconds !== 'undefined') {
        secondsLeft = this.seconds;
      } else {
        throw new TypeError("Missing datetime or seconds");
      }

      if (this.change) {
        secondsLeft -= this.newChange;
        this.newChange++;
      }

      let seconds = Math.abs(secondsLeft);

      let d = Math.floor(seconds / (3600 * 24));
      let h = Math.floor(seconds % (3600 * 24) / 3600);
      let m = Math.floor(seconds % 3600 / 60);
      let s = Math.floor(seconds % 60);

      if (d) {
        if (h) {
          this.formatted = d + this.formats.days[this.format] + (d !== 1 && this.format === 'long' ? 's' : '') + ' ' + h + this.formats.hours[this.format] + (h !== 1 && this.format === 'long' ? 's' : '');
        } else {
          this.formatted = d + this.formats.days[this.format] + (d !== 1 && this.format === 'long' ? 's' : '');
        }
      } else if (h) {
        if (m) {
          this.formatted = h + this.formats.hours[this.format] + (h !== 1 && this.format === 'long' ? 's' : '') + ' ' + m + this.formats.minutes[this.format] + (m !== 1 && this.format === 'long' ? 's' : '');
        } else {
          this.formatted = h + this.formats.hours[this.format] + (h !== 1 && this.format === 'long' ? 's' : '');
        }
      } else if (m) {
        if (s) {
          if (m <= 4) {
            this.formatted = m + this.formats.minutes[this.format] + (m !== 1 && this.format === 'long' ? 's' : '') + ' ' + s + this.formats.seconds[this.format] + (s !== 1 && this.format === 'long' ? 's' : '');
          } else {
            this.formatted = (this.format === 'long' ? 'about ' : '') + m + this.formats.minutes[this.format] + (m !== 1 && this.format === 'long' ? 's' : '');
          }
        } else if (m > 1) {
          this.formatted = (this.format === 'long' ? 'about ' : '') + m + this.formats.minutes[this.format] + (m !== 1 && this.format === 'long' ? 's' : '');
        } else {
          this.formatted = m + this.formats.minutes[this.format] + (m !== 1 && this.format === 'long' ? 's' : '');
        }
      } else if (s) {
        this.formatted = s + this.formats.seconds[this.format] + (s !== 1 && this.format === 'long' ? 's' : '');
      } else {
        this.formatted = 'now';
      }

      if (this.suffix) {
        if (secondsLeft > 0) {
          this.formatted = 'in ' + this.formatted;
        } else if (secondsLeft < 0) {
          this.formatted = this.formatted + ' ago';
        }
      }
    },
  },
  mounted() {
    this.updateTime();
    if (this.autoUpdate) {
      this.updateTimer = setInterval(this.updateTime, this.autoUpdate);
    }
  },
  beforeUnmount() {
    clearInterval(this.updateTimer);
  }
}
</script>
