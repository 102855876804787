import { createWebHistory, createRouter } from 'vue-router';
import TournamentList from '@/components/tournaments/TournamentList.vue';
import TournamentInfo from '@/components/tournaments/TournamentInfo.vue';
import TournamentWinner from '@/components/tournaments/TournamentWinner.vue';
import TournamentLeaderboard from '@/components/tournaments/TournamentLeaderboard.vue';
import StyleGuide from '@/components/helpers/StyleGuide.vue';

const routes = [
  {
    path: '/',
    name: 'tournaments',
    component: TournamentList,
  },
  {
    path: '/custom',
    name: 'custom',
    component: TournamentList,
  },
  {
    path: '/history',
    name: 'history',
    component: TournamentList,
  },
  {
    path: '/tournament/:id',
    name: 'tournament-info',
    component: TournamentInfo,
  },
  {
    path: '/tournament/:id/winner',
    name: 'tournament-winner',
    component: TournamentWinner,
  },
  {
    path: '/leaderboard',
    name: 'leaderboard',
    component: TournamentLeaderboard,
  },
  {
    path: '/styles',
    name: 'styles',
    component: StyleGuide,
  }
];

const router = new createRouter({
  history: createWebHistory(),
  routes
});

export default router;
