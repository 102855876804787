<template>
  <div>
    <div class="header is-small">
      <div class="flex">
        <div class="flex-none w-10">
          <div class="cursor-pointer p-1" @click="$router.push('/')">
            <font-awesome-icon :icon="['fa', 'chevron-left']" size="lg"/>
          </div>
        </div>

        <div class="grow-1">
          <div class="title">Style Guide</div>
        </div>
      </div>
    </div>
    <h1 class="font-bold p-4">Test Buttons</h1>

    <h1 class="font-bold p-4">Tournament List</h1>
    <div class="tabs">
      <div class="tab is-active">Regular</div>
      <div class="tab">Active <span class="font-bold">5</span></div>
      <div class="tab">History</div>
    </div>

    <h1 class="font-bold p-4">Player List</h1>
    <div class="flex w-full border-b border-neutral-200 py-2 gap-4 hover:bg-neutral-100 px-4">
      <div class="flex-none w-16 pt-1">
        <div :class="'rank-0'" class="tag is-rank">1</div>
        <a :href="`clashroyale://specHostedMatch?match_tag=2JC8CCC&player_tag=PVJVYYY2U`">
          <img :src="$data['spectate']" alt="spectate" class="spectate-icon">
        </a>
      </div>
      <div class="flex-1">
        <div class="block font-bold text-lg">SomeGuy</div>
        <p class="font-sm text-neutral-400 h-5">
          <img :src="'https://cdn.statsroyale.com/images/badges/'+ 16000001 +'.png'"
               alt="clan icon" class="inline h-full"> Some Clan
        </p>
      </div>
      <div class="text-right">
        <span class="text-blue-600">W1</span> &middot; <span
          class="text-red-500">L0</span>
        <p class="text-lg whitespace-nowrap">
          <img :src="$data['coin']" alt="coins" class="coin-icon">{{
            Number(1000)
            .toLocaleString()
          }}
        </p>
      </div>
    </div>

    <div class="toggles p-4">
      <div class="toggle is-active">Any</div>
      <div class="toggle"><img :alt="$data['regularText']" :src="$data['regular']"></div>
      <div class="toggle"><img :alt="$data['mirrorText']" :src="$data['mirror']"></div>
      <div class="toggle"><img :alt="$data['draftText']" :src="$data['draft']"></div>
      <div class="toggle"><img :alt="$data['tripleElixirText']" :src="$data['tripleElixir']"></div>
    </div>

    <div v-for="tournament in tournaments" v-bind:key="tournament.id">
      <tournament-list-row :tournament="tournament"></tournament-list-row>
    </div>

    <h1 class="font-bold p-4">Buttons</h1>
    <div class="flex gap-4 flex-wrap px-4">
      <button class="button">Create</button>
      <button class="button is-primary">Create</button>
      <button class="button is-danger">Create</button>
      <div class="relative p-4">
        <div class="absolute right-0 top-0 text-neutral-600 hover:text-black">
          <drop-down
              :icon="['fa', 'ellipsis-vertical']"
              :items="[
                { text: 'View Leaderboard', icon: ['fa', 'trophy'] },
                { text: 'Discord Community', icon: ['fa-brands', 'discord'] },
                { divider: true },
                { text: 'Logout', icon: ['fa', 'arrow-right-from-bracket'] },
            ]"
              type="icon"
          >
          </drop-down>
        </div>
      </div>
      <drop-down
          :iconLeft="['fa', 'trophy']"
          :items="[
                { text: 'View Leaderboard', icon: ['fa', 'trophy'] },
                { text: 'Discord Community', icon: ['fa-brands', 'discord'] },
                { divider: true },
                { text: 'Logout', icon: ['fa', 'arrow-right-from-bracket'] },
            ]"
          text="Left"
          type="button"
      >
      </drop-down>

      <drop-down
          :iconRight="['fa', 'chevron-down']"
          :items="[
                { text: 'View Leaderboard', icon: ['fa', 'trophy'] },
                { text: 'Discord Community', icon: ['fa-brands', 'discord'] },
                { divider: true },
                { text: 'Logout', icon: ['fa', 'arrow-right-from-bracket'] },
            ]"
          text="Right"
          type="button"
      >
      </drop-down>

    </div>

    <h1 class="font-bold p-4">Skeleton</h1>
    <div class="px-4">
      <div class="mt-2 skeleton w-24"></div>
      <div class="mt-2 skeleton w-48"></div>
      <div class="mt-2 skeleton w-32"></div>
    </div>

    <h1 class="font-bold p-4">Colors</h1>
    <div v-for="(theme, themeName) in colors" v-bind:key="themeName">
      <h2 class="font-bold text-xs px-4 py-2">{{ themeName }}</h2>
      <template v-if="typeof theme === 'string'">
        <div class="px-4 text-xs text-black">
          <div :style="{ background: theme }" class="w-14 h-8 rounded border text-center"></div>
          <div class="text-black">{{ theme }}</div>
        </div>
      </template>
      <template v-else>
        <div class="flex flex-wrap gap-4 px-4 text-xs text-black">
          <div v-for="(color, value) in theme" v-bind:key="value">
            <div :style="{ background: color }"
                 class="w-14 h-8 rounded border text-center"></div>
            <div>{{ value }}<br/>{{ color }}</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>

import icons from '../../mixins/icons.js';
import tailwind from '../../../tailwind.config.js';
import DropDown from '@/components/global/DropDown.vue';
import TournamentListRow from "@/components/tournaments/TournamentListRow";

export default {
  data() {
    return {
      colors: tailwind.theme.extend.colors,
      tournaments: [],
    }
  },
  name: "TournamentList.vue",
  mixins: [
    icons,
  ],
  computed: {},
  components: {
    DropDown,
    TournamentListRow,
  },
  mounted() {
    this.tournaments = [
      {
        id: 0,
        eventId: 'skeleton_brunch',
        cost: 1000,
        mode: 'regular',
        playerLimit: 256,
        playerCount: 5,
        startsAt: { _seconds: Math.round(new Date().getTime() / 1000) + 3600 + 60 * 15 },
        registrationAt: { _seconds: Math.round(new Date().getTime() / 1000) + 3600 },
        status: 'registering',
      },
      {
        id: 1,
        eventId: 'karaoke_nite',
        cost: 1000,
        mode: 'tripleElixir',
        playerLimit: 1024,
        playerCount: 992,
        startsAt: { _seconds: Math.round(new Date().getTime() / 1000) + 25 + 60 * 15 },
        registrationAt: { _seconds: Math.round(new Date().getTime() / 1000) + 25 },
        status: 'registering',
      },
      {
        id: 2,
        eventId: '',
        cost: 500,
        mode: 'draft',
        playerLimit: 8,
        playerCount: 4,
        status: 'registering',
      },
    ]
  },
  methods: {}
}
</script>

<style lang="scss" module>
</style>