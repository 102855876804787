<template>
  <div
      class="flex cursor-pointer hover:bg-neutral-100 border-b border-neutral-200 py-3 select-none items-center"
      @click="tournament.id ? { click: $router.push({name: 'tournament-info', params: { id: tournament.id }}) } : {}"
  >
    <div class="flex-none pr-4 pl-4">
      <div :class="tournament.eventId" class="gameMode">
        <img :alt="tournament.mode"
             :src="`https://cdn.statsroyale.com/tournaments/mode-icons/${tournament.mode}.png`">
      </div>
    </div>
    <div class="flex-1 grow-1 pr-4 h-full">
      <div class="font-bold">
        {{ tournament.playerCount || 0 }}/{{ tournament.playerLimit }} players
        <span v-if="myActiveTournament === tournament.id"
              class="tag is-success is-small">You Joined</span>
        <span v-else-if="tournament.eventId" :class="tournament.eventId"
              class="tag is-small">{{ $data[tournament.eventId] }}</span>
      </div>
      <div class="text-neutral-400 text-xs">
        <template v-if="tournament.status === 'completed'">
          Ended
          <short-time
              :auto-update="1000"
              :change="1"
              :seconds="Math.floor(tournament.updatedAt._seconds - serverTime().getTime() / 1000)"></short-time>
        </template>
        <span v-else-if="tournament.status === 'registering'">
          <template v-if="secondsToRegistration">
            Registration <short-time :auto-update="100"
                                     :seconds="secondsToRegistration"></short-time>
          </template>
          <template v-else>
            Registration Phase
          </template>
        </span>
        <span v-else class="capitalize">{{ tournament.status }}</span>
      </div>
    </div>
    <div class="text-right pr-4">
      <p class="text-md align-center">
        <template
            v-if="tournament.status === 'completed' && tournament.players && tournament.players[myTag]">
          <img :src="$data['coin']" alt="coins"
               class="coin-icon">{{ Number(tournament.players[myTag].prize).toLocaleString() }}
        </template>
        <template v-else>
          <img :src="$data['coin']" alt="coins" class="coin-icon">{{
            tournament.cost ? Number(tournament.cost).toLocaleString() : "FREE"
          }}
        </template>
      </p>
      <p class="text-neutral-400 text-xs">
        <template
            v-if="tournament.status === 'completed' && tournament.players && tournament.players[myTag]">
          Prize
        </template>
        <template v-else>
          Buy-in price
        </template>
      </p>
    </div>
  </div>
</template>

<script>

import { mapGetters } from "vuex";
import icons from '../../mixins/icons.js';
import ShortTime from '@/components/global/ShortTime.vue';

export default {
  name: "TournamentListRow",
  props: {
    tournament: {
      Type: Object,
    },
  },
  data() {
    return {
      secondsToRegistration: 0,
      updateTimer: null,
    }
  },
  mixins: [
    icons,
  ],
  components: {
    ShortTime,
  },
  computed: {
    ...mapGetters({
      myActiveTournament: 'getActiveTournament',
      serverTime: 'getServerTime',
      myTag: 'getTag',
    }),
  },
  mounted() {
    this.updateRegistration();
    this.updatetimer = setInterval(this.updateRegistration, 100);
  },
  beforeUnmount() {
    clearInterval(this.updateTimer);
  },
  methods: {
    updateRegistration() {
      if (!this.tournament.registrationAt) {
        this.secondsToRegistration = 0;
        return;
      }

      if (this.tournament.registrationAt && this.tournament.registrationAt._seconds > (this.serverTime()
      .getTime() / 1000)) {
        this.secondsToRegistration = this.tournament.registrationAt._seconds - Math.floor(this.serverTime()
        .getTime() / 1000);
      } else {
        this.secondsToRegistration = 0;
      }
    }
  }
}
</script>
