<!--suppress ALL -->
<template>
  <div>
    <div
        :style="{ backgroundImage: 'url(https://cdn.statsroyale.com/tournaments/back-standard.png)' }"
        class="header bg-blue is-medium">
      <div v-if="tournament && myConnectionStatus === 'connected'">
        <div class="relative">
          <div class="absolute top-0 right-0 text-neutral-200 hover:text-white">
            <input id="tournament-id" :value="tournament.id" type="hidden">
            <drop-down
                :icon="['fa', 'ellipsis-vertical']"
                :items="[
//                { divider: true },
                { key: 0, text: 'Copy Tournament ID', icon: ['fa', 'copy'], click: copyId },
            ]"
                type="icon"
            >
            </drop-down>
          </div>
        </div>

        <div class="flex">

          <div class="flex-none w-10">
            <div class="cursor-pointer p-1" @click="goBack()">
              <font-awesome-icon :icon="['fa', 'chevron-left']" size="lg"/>
            </div>
          </div>

          <div class="grow-1 w-full">
            <p class="title">
              {{ $data[tournament.mode + 'Text'] }}
            </p>
            <p class="font-bold text-sm">
              <img :src="$data['coin']" class="coin-icon">{{
                tournament.cost ? Number(tournament.cost)
                .toLocaleString() : 'FREE'
              }} Buy-in &middot;
              Max losses: {{ tournament.maxLosses }}
            </p>

            <div v-if="tournament.status === 'registering'">
              <p class="text-sm opacity-80 py-1">
                <template
                    v-if="!tournament.playerMinimum || tournament.playerMinimum === tournament.playerLimit">
                  Starts automatically once full
                </template>
                <template v-else-if="secondsToStart">
                  Starts
                  <short-time :seconds="secondsToStart" auto-update="0"></short-time>
                </template>
                <template v-else>
                  Starting shortly...
                </template>
              </p>

              <div v-if="tournament.playerCount < tournament.playerLimit" class="progress my-2">
                <div
                    :style="{ width: (tournament.playerCount / tournament.playerLimit * 100) + '%' }"
                    class="bar is-primary"></div>
                <div class="value">
                  {{ tournament.playerCount }}/{{ tournament.playerLimit }} players
                </div>
              </div>

              <div class="py-2">
                <button ref="registerButton" class="button is-primary w-full hidden"
                        @click="registerTournamentModal()">Register
                </button>
                <button ref="registerLaterButton" class="button is-primary w-full hidden" disabled>
                  Register
                  <short-time :auto-update="100" :seconds="secondsToRegistration"></short-time>
                </button>
                <button ref="leaveButton" class="button is-danger w-full hidden"
                        @click="leaveTournament()">Leave
                </button>
              </div>
            </div>
            <div v-else-if="tournament.status === 'completed'" class="mt-2">
              <template v-if="myTag === orderedPlayers[0].id">
                <p class="text-sm mb-2">Congratulations, you won!</p>
              </template>
              <div class="tag is-success">Ended
                <short-time
                    :auto-update="1000"
                    :change="1"
                    :seconds="Math.floor(tournament.updatedAt._seconds - serverTime().getTime() / 1000)"></short-time>
              </div>
            </div>
            <div v-else>
              <p>Tournament has started</p>

              <template
                  v-if="this.tournament && this.tournament.players && this.tournament.players[this.myTag]">
                <template
                    v-if="matchTimer && (myStatus !=='accepted' || opponentStatus !== 'accepted')">
                  <div class="progress my-1">
                    <div :class="{'is-danger': matchTimer < 30, 'is-warning': matchTimer < 60 }"
                         :style="{ width: (matchTimer / 180 * 100) + '%' }"
                         class="bar is-primary"></div>

                    <div class="value">
                      <short-time :auto-update="100" :seconds="matchTimer"
                                  :suffix="false"></short-time>
                    </div>
                  </div>

                  <div class="my-1 text-sm">
                    <template v-if="myStatus === 'noResponse'">
                      Please accept the battle
                    </template>
                    <template v-else-if="opponentStatus === 'noResponse'">
                      Waiting for opponent
                    </template>
                  </div>
                  <div class="relative">
                    <a class="absolute button is-primary w-full"
                       href="https://link.clashroyale.com/en" target="_blank">Open Clash Royale</a>
                  </div>
                </template>
                <div v-else-if="myStatus === 'accepted'">
                  You are currently playing
                </div>
                <div v-else-if="myActiveTournament === tournament.id">
                  Finding your next opponent...
                </div>
                <div v-else>
                  You have no more battles to play
                </div>
              </template>
            </div>
          </div>
          <div class="flex-non w-14"></div>
        </div>
      </div>
      <div v-else>
        <div class="flex">
          <div class="flex-none w-10">
            <div class="cursor-pointer p-1" @click="$router.go(-1)">
              <font-awesome-icon :icon="['fa', 'chevron-left']" size="lg"/>
            </div>
          </div>

          <div class="grow-1 w-full">
            <p class="title">Tournament Loading</p>
            <div class="mt-2 skeleton"></div>
            <div class="mt-4 skeleton w-1/2"></div>
            <div class="mt-4 skeleton w-2/3"></div>
            <div class="mt-4 skeleton w-full"></div>
          </div>
          <div class="flex-none w-10"></div>
        </div>
      </div>
    </div>
    <div class="tabs">
      <button :class="{ 'is-active': tournamentTab === 'players' }" class="tab"
              @click="tournamentTab = 'players'">Players
      </button>
      <button :class="{ 'is-active': tournamentTab === 'brackets' }"
              class="tab" @click="tournamentTab = 'brackets'">Brackets
      </button>
      <button :class="{ 'is-active': tournamentTab === 'details' }" class="tab"
              @click="tournamentTab = 'details'">Details
      </button>
    </div>
    <div v-if="tournamentTab === 'details'" class="p-4">
      <p class="font-bold">Prize Distribution</p>
      <div v-if="tournament && tournament.reward && tournament.reward === 'coins'">
        <div v-for="(coins, index) in getPrizePool">
          <div class="flex-none w-full pt-1">
            <div :class="'rank-' + index" class="tag is-rank">{{ index + 1 }}</div>
            {{
              Number(coins)
              .toLocaleString()
            }} <img :src="$data['coin']" alt="coins" class="coin-icon">
          </div>
        </div>
      </div>

      <p v-if="tournament.status === 'registering'" class="pt-2 text-sm text-gray-400">
        Prize distribution may be adjusted as people join or leave the tournament before it
        starts.</p>
    </div>
    <div v-else-if="tournamentTab === 'brackets'" class="py-4">
      <tournament-bracket :tournament="tournament"></tournament-bracket>
    </div>
    <template v-else-if="orderedPlayers.length && myConnectionStatus === 'connected'">
      <div v-for="(player, index) in orderedPlayers"
           class="flex w-full border-b border-neutral-200 py-2 gap-4 hover:bg-neutral-100 px-4"
           v-bind:class="{ 'bg-green-50': myTag === player.id }">
        <div class="flex-none w-16 pt-1">
          <div :class="'rank-' + index" class="tag is-rank">{{ index + 1 }}</div>
          <template v-if="player.match" class="relative">
            <template v-if="tournament.battles[player.match].state !== 'pending'">
              <a :href="`clashroyale://specHostedMatch?match_tag=` +
              player.match.split('#')[1] + `&player_tag=` + player.id.split('#')[1]">
                <img :src="$data['spectate']" alt="spectate" class="spectate-icon">
              </a>
            </template>
            <template v-else class="relative">
              <font-awesome-icon
                  v-if="tournament.battles[player.match].invites[player.id] === 'accepted'"
                  :icon="['fa', 'square-check']" class="text-green-500 match-status-icon"
                  fixed-width size="lg"/>
              <font-awesome-icon
                  v-else-if="tournament.battles[player.match].invites[player.id] === 'noResponse'"
                  :icon="['fa', 'spinner']"
                  class="text-neutral-500 match-status-icon fa-spin-pulse"
                  fixed-width size="lg"/>
              <font-awesome-icon
                  v-else-if="tournament.battles[player.match].invites[player.id] === 'declined'"
                  :icon="['fa', 'square-xmark']" class="text-neutral-500 match-status-icon"
                  fixed-width size="lg"/>
            </template>
          </template>
          <span v-else>
            <img :src="$data['spectate']" alt="no spectate" class="spectate-icon-off">
          </span>
        </div>
        <div class="flex-1">
          <div class="block font-bold text-lg">{{ player.name }}</div>
          <p v-if="player.clan && player.clan.name" class="font-sm text-neutral-400 h-5">
            <img :src="'https://cdn.statsroyale.com/images/badges/'+ player.clan.badgeId +'.png'"
                 alt="clan icon" class="inline h-full"> {{ player.clan.name }}
          </p>
          <p v-else class="font-sm text-neutral-400">
            <img :src="'https://cdn.statsroyale.com/images/badges/0.png'" alt="clan icon"
                 class="inline h-5"> No Clan
          </p>
        </div>
        <div class="text-right">
          <span class="text-blue-600">W{{ player.wins || 0 }}</span> &middot; <span
            class="text-red-500">L{{ player.losses || 0 }}</span>
          <p v-if="player.prize" class="text-lg whitespace-nowrap">
            <img :src="$data['coin']" alt="coins" class="coin-icon">{{
              Number(player.prize)
              .toLocaleString()
            }}
          </p>
        </div>
      </div>
    </template>
    <div v-else-if="tournament && myConnectionStatus === 'connected'" class="text-center pt-6">
      <img :src="$data['dummy']" class="inline h-36">
      <p class="m-4 text-base">Waiting for players</p>
    </div>
    <div v-else>
      <div v-for="index in [0,1,2,3,4]"
           class="flex w-full border-b border-neutral-200 py-2 gap-4 hover:bg-neutral-100 px-4">
        <div class="flex-none w-16">
          <div class="skeleton w-1/3"></div>
        </div>
        <div class="flex-1">
          <div class="block skeleton w-2/3"></div>
          <div class="block skeleton w-1/2"></div>
        </div>
        <div class="flex-1">
          <div class="grid place-items-end">
            <div class="skeleton w-1/3"></div>
            <div v-if="index === 0" class="skeleton w-1/5"></div>
          </div>
        </div>
      </div>
    </div>

    <modal-box v-if="tournament" v-show="isShowRegisterModal" :close-button="closeRegistration"
               title="Registration">
      <template v-slot:body>
        <div class="flex border-b border-neutral-300 py-2 px-4">
          <div class="grow">Buy-in</div>
          <div class="font-mono text-right grow"><img :src="$data['coin']" alt="coin"
                                                      class="coin-icon">{{
              Number(tournament.cost)
              .toLocaleString()
            }}
          </div>
        </div>
        <div class="flex border-b border-neutral-300 py-2 px-4">
          <div class="grow">Prize Pool</div>
          <div class="font-mono text-right grow"><img :src="$data['coin']" alt="coin"
                                                      class="coin-icon">{{
              Number(tournament.cost * tournament.playerLimit * 0.90)
              .toLocaleString()
            }}
          </div>
        </div>
        <div class="flex border-b border-neutral-300 py-2 px-4">
          <div class="grow">Current Balance</div>
          <div class="font-mono text-right grow"><img :src="$data['coin']" alt="coin"
                                                      class="coin-icon">{{
              Number(myCoins)
              .toLocaleString()
            }}
          </div>
        </div>
        <div class="flex border-b border-neutral-300 py-2 px-4">
          <div class="grow">New Balance</div>
          <div class="font-mono text-right grow"><img :src="$data['coin']" alt="coin"
                                                      class="coin-icon">{{
              Number(myCoins - tournament.cost)
              .toLocaleString()
            }}
          </div>
        </div>

        <div v-if="errMsg" class="py-2 px-4">
          <span class="tag is-danger w-full">{{ errMsg }}</span>
        </div>

      </template>
      <template v-slot:footer>
        <button class="button w-1/2" @click="closeRegistration()">Close</button>
        <button v-if="tournament.playerCount < tournament.playerLimit && !registering"
                class="button w-1/2 is-primary"
                @click="registerTournament()">Confirm
        </button>
        <button v-else-if="!registering" class="button w-1/2 is-primary" disabled>Register</button>
        <button v-else class="button w-1/2 is-primary loading" disabled>
          <font-awesome-icon :icon="['fa', 'spinner']" spin/>
          Registering
        </button>
      </template>
    </modal-box>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import icons from '@/mixins/icons.js';
import ModalBox from '@/components/global/ModalBox.vue';
import DropDown from '@/components/global/DropDown.vue';
import ShortTime from '@/components/global/ShortTime.vue';
import TournamentBracket from '@/components/tournaments/TournamentBracket.vue';
import SocketIoService from '@/services/socketio.service';
import { orderBy } from 'lodash';
import { useRoute } from "vue-router";
import { useStore } from 'vuex';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';

export default {
  data() {
    return {
      route: useRoute(),
      store: useStore(),
      isShowRegisterModal: false,
      registering: false,
      errMsg: null,
      matchTimer: null,
      tournamentTimer: null,
      registerButtonTimer: null,
      secondsToStart: 0,
      tournamentStartDelayTimer: null,
      tournamentTab: 'players',
      lastPath: null,
      leaving: false,
      secondsToRegistration: 0,
    }
  },
  mixins: [
    icons
  ],
  name: "TournamentInfo.vue",
  computed: {
    ...mapGetters({
      tournament: 'getTournament',
      myTag: 'getTag',
      myCoins: 'getCoins',
      myActiveTournament: 'getActiveTournament',
      myTournamentStatus: 'getRegisterTournamentStatus',
      serverTime: 'getServerTime',
      myConnectionStatus: 'getConnectionStatus',
    }),
    orderedPlayers: function () {
      if (!this.tournament || !this.tournament.players) {
        return [];
      }

      let players = [];

      // we need to save the key for later, for the spectate buttons
      for (let playerTag in this.tournament.players) {
        players.push({
          ...this.tournament.players[playerTag],
          id: playerTag,
          joinedAt: new Date(this.tournament.players[playerTag].joinedAt._seconds * 1000)
        });
      }

      return orderBy(players, ['prize', 'wins', 'joinedAt'], ['desc', 'desc', 'asc']);
    },
    orderedBattles: function () {
      if (!this.tournament || !this.tournament.rounds) {
        return [];
      }

      let rounds = [];

      for (let round in this.tournament.rounds) {
        rounds[round] = [];
        for (let battle in this.tournament.rounds[round]) {
          rounds[round][battle] = this.tournament.rounds[round][battle];
        }
      }

      return rounds;
    },
    opponentStatus: function () {
      if (this.tournament.players[this.myTag].match &&
          this.tournament.battles[this.tournament.players[this.myTag].match] &&
          this.opponentTag
      ) {
        return this.tournament.battles[this.tournament.players[this.myTag].match].invites[this.opponentTag];
      }

      return null;
    },
    opponentTag: function () {
      if (this.tournament.players[this.myTag].match && this.tournament.battles[this.tournament.players[this.myTag].match]) {
        for (let playerTag in this.tournament.battles[this.tournament.players[this.myTag].match].invites) {
          if (playerTag !== this.myTag) {
            return playerTag;
          }
        }
      }

      return null;
    },
    myStatus: function () {
      if (this.tournament && this.tournament.battles && this.tournament.players[this.myTag].match) {
        return this.tournament.battles[this.tournament.players[this.myTag].match].invites[this.myTag];
      }

      return null;
    },
    getPrizePool: function () {
      if (!this.tournament) {
        return [];
      }

      let players = [];
      let totalPlayers = this.tournament.playerLimit;

      if (this.tournament.playerCount) {
        totalPlayers = this.tournament.playerCount;
      }

      if (totalPlayers < this.tournament.playerMinimum) {
        totalPlayers = this.tournament.playerMinimum;
      }

      let prizePool = this.tournament.coinReward || Math.floor(this.tournament.cost * totalPlayers * 0.90);

      if (this.tournament.coinReward || totalPlayers <= 4) {
        players[0] = prizePool;
      } else if (totalPlayers <= 8) {
        players[0] = Math.floor(prizePool * 0.70);
        players[1] = Math.floor(prizePool * 0.30);
      } else if (totalPlayers <= 16) {
        players[0] = Math.floor(prizePool * 0.60);
        players[1] = Math.floor(prizePool * 0.20);
        players[2] = Math.floor(prizePool * 0.10);
        players[3] = Math.floor(prizePool * 0.10);
      } else {
        players[0] = Math.floor(prizePool * 0.60);
        players[1] = Math.floor(prizePool * 0.20);
        players[2] = Math.floor(prizePool * 0.05);
        players[3] = Math.floor(prizePool * 0.05);
        players[4] = Math.floor(prizePool * 0.025);
        players[5] = Math.floor(prizePool * 0.025);
        players[6] = Math.floor(prizePool * 0.025);
        players[7] = Math.floor(prizePool * 0.025);
      }

      return players;
    }
  },
  created() {
    this.lastPath = this.$router.options.history.state.back;
  },
  beforeUnmount() {
  },
  watch: {
    $route(to, from) {
      // if we're staying on this page and changing hash, then let's handle this
      if (to.path === from.path && to.hash !== from.hash) {
        this.updateTournamentTab(to.hash);
      }
    },
    myTournamentStatus: {
      handler(newValue, oldValue) {
        if (newValue !== 'unregistered') {
          if (newValue !== 'ok' && newValue !== 'started') {
            this.errMsg = 'Unable to register: ' + newValue;
            this.registering = false;
          } else {
            this.isShowRegisterModal = false;
          }
        }
      },
      immediate: true,
    },
    tournament: {
      handler(newValue, oldValue) {
        this.checkForMatch();
        this.updateRegisterButton();
        this.updateSecondsToRegistration();
        this.updateSecondsToStart();
      }
    }
  },
  mounted() {
    if (this.route.params.id) {
      this.store.commit('tournament', null); // make sure it's blank at first
      this.tournamentSubscribe();
    }
    this.updateTournamentTab(this.$route.hash);
  },
  beforeRouteLeave(to, from, next) {
    SocketIoService.emit('tournament', { cmd: 'unsubscribe', id: this.route.params.id });
    this.store.commit('tournament', null); // make sure it's blank at first
    clearTimeout(this.tournamentTimer);
    clearTimeout(this.tournamentStartDelayTimer);
    clearTimeout(this.registerButtonTimer);
    next();
  },
  methods: {
    updateTournamentTab(hash) {
      switch (hash) {
        case '#players': {
          this.tournamentTab = 'players';
          break;
        }
        case '#brackets': {
          this.tournamentTab = 'brackets';
          break;
        }
        case '#details': {
          this.tournamentTab = 'details';
          break;
        }
        default: {
          this.tournamentTab = 'players';
        }
      }
    },
    getMatchStatusIcon: function (status, type) {
      switch (type) {
        case 'icon': {
          switch (status) {
            case 'accepted':
              return ['fa', 'square-check'];
            case 'noResponse':
              return ['fa', 'spinner'];
            case 'declined':
              return ['fa', 'square-xmark'];
          }
          break;
        }

        case 'class': {
          switch (status) {
            case 'accepted':
              return 'text-green-500';
            case 'noResponse':
              return 'text-neutral-500 fa-spin-pulse';
            case 'declined':
              return 'text-red-600';
          }
          break;
        }
      }
    },
    // this is in case the message got lost, we can send it again
    // this usually happens if there is a reconnection happening and something
    // is not happening at the right time, we want to make sure we have the proper info
    tournamentSubscribe: function () {
      if (!this.tournament) {
        clearTimeout(this.tournamentTimer);
        SocketIoService.emit('tournament', { cmd: 'subscribe', id: this.route.params.id });
        this.tournamentTimer = setTimeout(this.tournamentSubscribe, 1000);
      }
    },
    updateSecondsToRegistration: function () {
      if (!this.tournament) {
        setTimeout(this.updateSecondsToRegistration, 50);
        return;
      }

      if (!this.tournament.registrationAt) {
        return;
      }

      if (this.tournament.registrationAt._seconds > this.serverTime().getTime() / 1000) {
        this.secondsToRegistration = Math.floor(this.tournament.registrationAt._seconds - this.serverTime()
        .getTime() / 1000);
        setTimeout(this.updateSecondsToRegistration, 1000);
      } else {
        this.secondsToRegistration = 0;
      }
    },
    updateSecondsToStart: function () {
      if (!this.tournament || !this.tournament.startsAt) {
        this.secondsToStart = 0;
      } else if (this.tournament.startsAt._seconds > (this.serverTime().getTime() / 1000)) {
        this.secondsToStart = this.tournament.startsAt._seconds - Math.floor(this.serverTime()
        .getTime() / 1000);
      } else {
        this.secondsToStart = 0;
      }

      clearTimeout(this.tournamentStartDelayTimer);
      this.tournamentStartDelayTimer = setTimeout(this.updateSecondsToStart, 1000);
    },
    updateRegisterButton: function () {

      // button is visible
      clearTimeout(this.registerButtonTimer);

      if (this.$refs.registerButton && this.$refs.leaveButton) {
        this.registerButtonTimer = setTimeout(this.updateRegisterButton, 500);
        let buttons = {
          registerButton: false,
          leaveButton: false,
          registerLaterButton: false,
        }

        if (!this.tournament || this.tournament.status !== 'registering') {
          // we haven't loaded fully or we can't register/leave anymore
        } else if (this.myActiveTournament === this.tournament.id) {
          // we already joined this tournament, so we can leave it
          buttons.leaveButton = true;
          if (this.leaving) {
            this.$refs.leaveButton.disabled = true;
          } else {
            this.$refs.leaveButton.disabled = false;
          }
        } else if (!this.myTag) {
          // we are not logged in
          this.$refs.registerButton.innerText = 'Sign-in required';
          this.$refs.registerButton.disabled = true;
          buttons.registerButton = true;
        } else if (this.myActiveTournament && this.myActiveTournament !== this.tournament.id) {
          // we joined another tournament
          this.$refs.registerButton.innerText = 'You can join only one tournament';
          this.$refs.registerButton.disabled = true;
          buttons.registerButton = true;
        } else if (this.secondsToRegistration) {
          // registration is in the future
          buttons.registerLaterButton = true;
        } else if (this.myCoins < this.tournament.cost) {
          // we're too poor
          this.$refs.registerButton.innerText = 'Missing ' + (this.tournament.cost - this.myCoins) + ' coins to register';
          this.$refs.registerButton.disabled = true;
          buttons.registerButton = true;
        } else {
          this.$refs.registerButton.innerText = 'Register';
          this.$refs.registerButton.disabled = false;
          buttons.registerButton = true;
        }

        for (let i in buttons) {
          if (buttons[i]) {
            this.$refs[i].classList.remove('hidden');
          } else {
            this.$refs[i].classList.add('hidden');
          }
        }
      } else {
        // button isn't there yet, let's try again very soon
        this.registerButtonTimer = setTimeout(this.updateRegisterButton, 50);
      }
    },
    registerTournamentModal() {
      this.registering = false;
      this.errMsg = false;
      this.isShowRegisterModal = true;
    },
    registerTournament() {
      this.errMsg = false;
      this.registering = true;
      this.leaving = false;
      this.store.commit('tournamentRegister', { status: 'unregistered' });
      SocketIoService.emit('tournament', { cmd: 'register', id: this.route.params.id });
    },
    leaveTournament() {
      this.leaving = true;
      this.$refs.leaveButton.disabled = true;
      SocketIoService.emit('tournament', { cmd: 'unregister', id: this.route.params.id });
    },
    checkForMatch() {
      // that's kinda dumb but the tournament may not be loaded or fully loaded when the page opens up
      // so we want to put all these safe guards in, so nothing crashes or look weird
      if (
          this.myTag &&
          this.tournament &&
          this.tournament.players &&
          this.tournament.players[this.myTag] &&
          this.tournament.players[this.myTag].match &&
          this.tournament.battles[this.tournament.players[this.myTag].match]
      ) {
        let expireSeconds = Math.floor(this.tournament.battles[this.tournament.players[this.myTag].match].expireAt._seconds - this.serverTime()
        .getTime() / 1000);
        if (expireSeconds < 0) {
          expireSeconds = 0;
        }

        this.matchTimer = expireSeconds;
      }

      setTimeout(this.checkForMatch, 1000); // run this every second
    },
    getMS(seconds) {
      if (seconds > 60) {
        return (seconds / 60 | 0) + "m" + (seconds % 60).toString().padStart(2, 0) + "s";
      } else {
        return seconds.toString() + "s";
      }
    },
    closeRegistration() {
      this.isShowRegisterModal = false;
    },
    goBack() {
      if (this.lastPath) {
        this.$router.go(-1);
      } else {
        this.$router.push('/');
      }
    },
    copyId() {
      let tournamentId = document.querySelector('#tournament-id');
      tournamentId.setAttribute('type', 'text');
      tournamentId.select();

      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        alert('Tournament Id copied ' + msg);
      }
      catch (err) {
        alert('Unable to copy, id: ' + this.tournament.id);
      }

      /* unselect the range */
      tournamentId.setAttribute('type', 'hidden')
      window.getSelection().removeAllRanges()
    }
  },
  components: {
    DropDown,
    ModalBox,
    ShortTime,
    TournamentBracket,
  },
}
</script>

<style lang="scss" module>
</style>