<template>
  <header
      :style="{ backgroundImage: `url(https://cdn.statsroyale.com/tournaments/header-gradient.png)` }"
      class="header">
    <div class="relative">
      <div class="absolute top-0 right-0 text-neutral-200 hover:text-white">
        <drop-down
            :icon="['fa', 'ellipsis-vertical']"
            :items="[
                { key: 0, text: 'View Leaderboard', icon: ['fa', 'trophy'], click: goToLeaderboard },
                { key: 1, text: 'Discord Community', icon: ['fa-brands', 'discord'], url: 'https://discord.gg/statsroyale' },
                { key: 2, divider: true },
                { key: 3, text: 'Logout', icon: ['fa', 'arrow-right-from-bracket'], show: myTag !== null, click: showLogout },
                { key: 4, text: 'Login', icon: ['fa', 'arrow-right-to-bracket'], show: myTag === null, click: showLogin },
                { key: 5, text: 'Open Clash Royale', icon: ['fa', 'crown'], url: 'https://link.clashroyale.com/en'},
            ]"
            type="icon"
        >
        </drop-down>
      </div>
    </div>

    <h1 class="text-xl font-bold pb-2">Tournaments</h1>
    <div v-if="myConnectionStatus === 'connecting'">
      <div class="mt-2 skeleton w-24"></div>
      <div class="mt-2 skeleton w-48"></div>
      <div class="mt-2 skeleton w-32"></div>
    </div>
    <div v-else-if="!myTag">
      <p class="pb-2">Please link your Clash Royale account to participate in tournaments</p>
      <button class="button is-primary is-fullwidth" @click="openLinkModal()">Link My Account
      </button>
    </div>
    <div v-else>
      <p class="font-bold text-md">
        {{ myName }}
      </p>
      <p class="pb-2 text-md">
        <img :src="$data['coin']" alt="coin" class="coin-icon">
        {{ Number(myCoins).toLocaleString() }} coins
      </p>
      <button class="button is-primary" @click="getCoins()">
        <img :src="$data['coins']" alt="coins" class="coin-icon"> Get More Coins
      </button>
    </div>

    <modal-box v-show="!myOnBoardingShown" header-icon="starsIcons">
      <template v-slot:body>
        <p class="title">
          Welcome to Tournaments!
        </p>
        <p class="px-4 py-2">
          Reach the top of the leaderboard by winning coins and use them to enter special
          tournaments where you can win real prizes!
        </p>
        <p class="px-4 py-2">
          You start with <strong>1000 coins</strong> on the house. You can get more coins by winning
          tournaments or clicking the <strong>Get More Coins</strong> button!
        </p>
      </template>
      <template v-slot:footer>
        <button class="button is-primary w-full" @click="$store.commit('onBoardingShown', true)">
          Got it
        </button>
      </template>
    </modal-box>

    <modal-box v-show="isLogoutModalVisible" :close-button="closeLogout" title="Log out">
      <template v-slot:body>
        <p class="px-4 py-2">Do you really want to log out?</p>
      </template>
      <template v-slot:footer>
        <button class="button w-1/2" @click="closeLogout()">Close</button>
        <button class="button is-primary w-1/2" @click="logOut()">Log out</button>
      </template>
    </modal-box>

    <modal-box v-show="isLoginHelpModalVisible" title="Finding your tag and token">
      <template v-slot:body>
        <ol class="px-8 py-2 list-disc">
          <li>Launch Clash Royale</li>
          <li>Tap your name</li>
          <li>Tap the tag below your name and click copy</li>
          <li>Paste into the hashtag box</li>
          <li>Go to settings</li>
          <li>Click API Token twice at the bottom left</li>
          <li>Paste into the API token box</li>
        </ol>
      </template>
      <template v-slot:footer>
        <button class="button is-primary w-full"
                @click="isLoginHelpModalVisible = false; isLoginModalVisible = true">Close
        </button>
      </template>
    </modal-box>

    <modal-box v-show="isLoginModalVisible" :close-button="closeLogin" title="Link my account">
      <template v-slot:body>
        <div class="px-4 py-2">
          <p>Please enter your Clash Royale account information to play in tournaments.
            <a @click="isLoginHelpModalVisible = true; isLoginModalVisible = false">Need help?</a>
          </p>

          <label class="block pt-2" for="tag">Player Tag</label>
          <input id="tag" ref="tag" v-model="tag" placeholder="#XXXXXX" type="text">

          <label class="block pt-2" for="token">API Token</label>
          <input id="token" ref="token" v-model="token" autocomplete="off" placeholder="xxxxxx"
                 type="text">

          <div v-if="errorMsg" class="pt-4">
            <span class="tag is-danger">{{ errorMsg }}</span>
          </div>
        </div>
      </template>
      <template v-slot:footer>
        <button class="button grow w-1/2" @click="isLoginModalVisible = false">Close</button>
        <button class="button grow w-1/2 is-primary" @click="login()">Link Account</button>
      </template>
    </modal-box>

    <modal-box v-show="isCoinModalVisible" :close-button="closeCoins" header-icon="videoIcon">
      <template v-slot:body>
        <div class="px-4">
          <p class="subtitle">Free Coins</p>
          <p class="pb-2">
            Come back every 6 hours to claim 100 free coins.
          </p>

          <template v-if="isWatchAdsButtonVisible">
            <p class="pb-2">By using our app, you can earn extra bonus coins for watching ads.</p>
            <button id="get_coin_button" class="button is-primary w-full" @click="watchAd()">Get
              Bonus Coins
            </button>
          </template>
          <template v-else>
            <p class="pb-2">
              You can get a lot more coins by using our mobile apps!
            </p>
            <div class="flex gap-4">
              <div class="grow">
                <a href="https://apps.apple.com/us/app/stats-royale-for-clash-royale/id1264722932"
                   target="_blank">
                  <img :class="$style.appStore" :src="$data['apple']" alt="apple icon"
                       class="object-fill h-48 w-96">
                </a>
              </div>
              <div class="grow">
                <a href="https://play.google.com/store/apps/details?id=com.overwolf.statsroyale&hl=en_CA&gl=US"
                   target="_blank">
                  <img :class="$style.appStore" :src="$data['google']" alt="google icon"
                       class="object-fill h-48 w-96">
                </a>
              </div>
            </div>
          </template>
        </div>
      </template>
      <template v-slot:footer>
        <button class="button grow w-1/2" @click="closeCoins()">Close</button>
        <template v-if="myTag">
          <template v-if="secondsToNextFreeCoins > 0">
            <button class="button is-primary grow w-1/2" disabled>
              <short-time :auto-update="1000" :seconds="secondsToNextFreeCoins"
                          :suffix="false"></short-time>
            </button>
          </template>
          <template v-else>
            <button class="button is-primary grow w-1/2" @click="getFreeCoins()">Free Coins</button>
          </template>
          <div ref="freeCoinsBox" class="absolute right-14 hidden">
            <img :src="$data['coin']" alt="coins" class="coin-icon free-coins-icon"><span
              class="free-coins">+100</span>
          </div>
        </template>
      </template>

    </modal-box>

  </header>
</template>

<script>

import logIcon from '@/assets/icons/log.svg';
import modalBg from '@/assets/backgrounds/icons.png';
import starsIcons from '@/assets/backgrounds/stars-n-coins.png';
import icons from '@/mixins/icons.js';

const axios = require("axios");
import SocketIoService from "@/services/socketio.service";
import { mapGetters } from 'vuex'
import ModalBox from '@/components/global/ModalBox.vue';
import DropDown from '@/components/global/DropDown.vue';
import ShortTime from "@/components/global/ShortTime";

export default {
  data() {
    return {
      logIcon,
      modalBg,
      starsIcons,
      isCoinModalVisible: false,
      isLoginHelpModalVisible: false,
      isLoginModalVisible: false,
      isLogoutModalVisible: false,
      isWatchAdsButtonVisible: false,
      isMenuVisible: false,
      loginToken: false,
      tag: null,
      token: null,
      connecting: false,
      errorMsg: null,
      secondsToNextFreeCoins: 0,
      coinsTimer: null,
    };
  },
  mounted() {
    if (this.$route.query.hashtag) {
      this.tag = this.$route.query.hashtag;
    }
  },
  beforeUnmount() {
    clearInterval(this.coinsTimer);
  },
  components: {
    ModalBox,
    DropDown,
    ShortTime,
  },
  props: {},
  mixins: [
    icons
  ],
  computed: {
    ...mapGetters({
      ioServer: 'getIoServer',
      myTag: 'getTag',
      myToken: 'getToken',
      myName: 'getName',
      myCoins: 'getCoins',
      myNextFreeCoins: 'getNextFreeCoins',
      myOnBoardingShown: 'getOnBoardingShown',
      myConnectionStatus: 'getConnectionStatus',
      serverTime: 'getServerTime',
    })
  },
  methods: {
    goToLeaderboard() {
      this.$router.push({ name: 'leaderboard' })
    },
    getFreeCoins() {
      this.secondsToNextFreeCoins = 3600 * 6 - 1; // 6 hours
      clearInterval(this.coinsTimer);
      if (this.secondsToNextFreeCoins > 0) {
        this.coinsTimer = setInterval(() => {
          this.secondsToNextFreeCoins--;
        }, 1000);
      }
      SocketIoService.emit('player', { cmd: 'free-coins' });

      let elem = this.$refs.freeCoinsBox;
      elem.classList.remove('hidden');
      for (let i = 0; i <= 200; i++) {
        setTimeout(() => {
          if (i === 200) {
            elem.style.display = 'none';
          } else {
            elem.style.bottom = Math.floor(i / 4 + 30).toString() + "px";
            if (i > 100) {
              elem.style.opacity = ((200 - i) / 100).toString();
            } else {
              elem.style.opacity = '1';
            }
          }
        }, i * 2);
      }
    },
    getCoins() {
      this.secondsToNextFreeCoins = Math.floor((this.myNextFreeCoins.getTime() - this.serverTime()
      .getTime()) / 1000);
      clearInterval(this.coinsTimer);
      if (this.secondsToNextFreeCoins > 0) {
        this.coinsTimer = setInterval(() => {
          this.secondsToNextFreeCoins--;
        }, 1000);
      }

      this.isCoinModalVisible = true;
      this.isWatchAdsButtonVisible = false;

      if (window.ReactNativeWebView) {
        this.isWatchAdsButtonVisible = true;
      } else if (window.navigator.userAgent.indexOf("statsroyaleAndroid") > -1) {
        this.isWatchAdsButtonVisible = true;
      } else if (window.navigator.userAgent.indexOf("statsroyaleApple") > -1) {
        this.isWatchAdsButtonVisible = true;
      }
    },
    watchAd() {
      try {
        // react version waits for post message to execute...
        window.ReactNativeWebView.postMessage('I will only get this message in the app!');
      }
      catch (e) {
        console.log(e); // that didn't work
      }

      if (window.navigator.userAgent.indexOf("statsroyaleAndroid") > -1) {
        window.location.href = "statsroyale://tournamentsReward?" + this.myTag.replace('#', '%23');
      } else if (window.navigator.userAgent.indexOf("statsroyaleApple") > -1) {
        window.location.href = "statsroyale://tournamentsReward?" + this.myTag.replace('#', '%23');
      }
    },
    closeCoins() {
      clearInterval(this.coinsTimer);
      this.isCoinModalVisible = false;
    },
    logOut() {
      SocketIoService.emit('player', { cmd: 'sign-out' });
      this.$store.commit('token', null);
      this.$store.commit('player', null);
      this.isLogoutModalVisible = false;
      this.isMenuVisible = false;
    },
    openLinkModal() {
      this.isLoginModalVisible = true;

      this.$nextTick(() => {
        if (this.tag) {
          this.$refs.token.focus();
        } else {
          this.$refs.tag.focus();
        }
      });
    },
    async login() {
      if (this.tag && this.token) {
        try {
          this.tag = this.tag.toUpperCase();
          this.tag = this.tag.replace(/#/g, '');

          // common mistakes from peeps
          this.tag = this.tag.replace(/O/g, '0');
          this.tag = this.tag.replace(/B/g, '8');

          let response = await axios.get(this.ioServer + `/auth?tag=${this.tag}&token=${this.token}`);

          if (response && response.data && response.data.token) {
            this.$store.commit('token', response.data.token);
            this.$store.commit('connectionStatus', 'connecting');
            SocketIoService.socket.emit('player', { cmd: 'sign-in', token: response.data.token });
            this.isLoginModalVisible = false;
            this.errorMsg = null;
          } else {
            this.$store.commit('connectionStatus', 'connected');
            this.errorMsg = 'Invalid hashtag or token, please try again.';
          }
        }
        catch (err) {
          this.$store.commit('connectionStatus', 'connected');
          this.errorMsg = 'Unable to verify, please try again.';
        }
      } else {
        this.errorMsg = 'Please enter your tag and API token.'
      }
    },
    showLogin() {
      this.isLoginModalVisible = true;
    },
    closeLogin() {
      this.isLoginModalVisible = false;
    },
    showLogout() {
      this.isLogoutModalVisible = true;
    },
    closeLogout() {
      this.isLogoutModalVisible = false;
    },
  },
};
</script>

<style lang="scss" module>
.coin {
  float: left;
  height: 24px;
  padding: 4px 4px 4px 0;
}

.icon {
  margin-right: 5px;
  line-height: 16px;
}

.icon img {
  max-height: 24px;
  max-width: 24px;
}

.appStore {
  max-height: 48px;
}
</style>
