import regularIcon from '@/assets/game-modes/regular.png';
import tripleElixirIcon from '@/assets/game-modes/tripleElixir.png';
import draftIcon from '@/assets/game-modes/draft.png';
import duelIcon from '@/assets/game-modes/duel.png';
import tripleDraftIcon from '@/assets/game-modes/tripleDraft.png';
import mirrorIcon from '@/assets/game-modes/mirror.png';
import coinIcon from '@/assets/icons/coin.png';
import coinsIcon from '@/assets/icons/3-coin.png';
import spectateIcon from '@/assets/icons/observe.png';
import dummyIcon from '@/assets/icons/matches-dummy.svg';
import appleIcon from '@/assets/icons/app-store-apple.svg';
import googleIcon from '@/assets/icons/app-store-google.svg';
import leaderboardIcon from '@/assets/icons/leaderboard.svg';

export default {
  data() {
    return {

      // our icons
      leaderboardIcon: leaderboardIcon,
      coin: coinIcon,
      coins: coinsIcon,
      regular: regularIcon,
      tripleElixir: tripleElixirIcon,
      mirror: mirrorIcon,
      duel: duelIcon,
      draft: draftIcon,
      tripleDraft: tripleDraftIcon,
      spectate: spectateIcon,
      dummy: dummyIcon,
      apple: appleIcon,
      google: googleIcon,

      // text really shouldn't be here but whatever
      regularText: 'Regular',
      tripleElixirText: 'Triple Elixir',
      draftText: 'Draft',
      tripleDraftText: 'Triple Draft',
      mirrorText: 'Mirror',
      duelText: 'Duel',

      'karaoke_nite': 'Karaoke Nite',
      'free_roll': 'Free Roll',
      'skeleton_brunch': 'Skeleton Brunch',
    };
  },
};
