module.exports = {
  content: [
    "./index.html",
    "./src/**/*.{vue,js,ts,jsx,tsx}",
  ],
  theme: {
    fontFamily: {
      sans: ['ProximaNova', 'sans-serif'],
      serif: ['Merriweather', 'serif'],
      mono: ['monospace', 'monospace']
    },
    extend: {
      // https://material.io/design/color/the-color-system.html#tools-for-picking-colors
      colors: {
        'blue': {
          50: '#e3f2ff',
          100: '#badfff',
          200: '#8dcbff',
          300: '#5ab7ff',
          400: '#2fa6ff',
          500: '#0097ff',
          600: '#0088ff',
          700: '#0e75eb',
          800: '#1463d8',
          900: '#1943b9'
        },
        'zodiac': {
          50: '#e5e9ee',
          100: '#bdc8d6',
          200: '#94a4ba',
          300: '#6c819e',
          400: '#4c678c',
          500: '#2a4f7c',
          600: '#234874',
          700: '#1b3e69',
          800: '#15355c',
          900: '#0f2544'
        },
        'neutral': {
          50: '#FAFAFA',
          100: '#F5F5F5',
          200: '#EEEEEE',
          300: '#e0e0e0',
          400: '#bdbdbd',
          500: '#9f9f9f',
          600: '#767676',
          700: '#626262',
          800: '#434343',
          900: '#222222'
        },
        'red': {
          50: '#feecee',
          100: '#fdd0d3',
          200: '#eb9f9c',
          300: '#e07b77',
          400: '#e96055',
          500: '#ee553d',
          600: '#df4b3b',
          700: '#cd4235',
          800: '#c03c2f',
          900: '#b03425'
        },
        'green': {
          50: '#eaf6e7',
          100: '#cde8c5',
          200: '#add9a1',
          300: '#8dcc7a',
          400: '#74c05d',
          500: '#5eb53f',
          600: '#54a636',
          700: '#47942b',
          800: '#3b8321',
          900: '#24640c'
        },
        'yellow': {
          50: '#fefce6',
          100: '#fbf7c2',
          200: '#f9f299',
          300: '#f7ed70',
          400: '#f4e84f',
          500: '#f1e32c',
          600: '#f3d329',
          700: '#f2bb20',
          800: '#f0a315',
          900: '#ed7a00'
        },
      },
      boxShadow: {
        't-sm': '0 -1px 2px 0 rgba(0, 0, 0, 0.05)',
        't-md': '0 -4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
        't-lg': '0 -10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
        't-xl': '0 -20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04)',
        't-2xl': '0 -25px 50px -12px rgba(0, 0, 0, 0.25)',
        't-3xl': '0 -35px 60px -15px rgba(0, 0, 0, 0.3)',
      },
      backgroundSize: {
        '25': '25%',
        '50': '50%',
        '75': '75%',
      },
      minWidth: {
        '1': '0.25rem',
        '2': '0.5rem',
        '3': '0.75rem',
        '4': '1rem',
        '5': '1.25rem',
        '6': '1.5rem',
        '7': '1.75rem',
        '8': '2rem',
      },
      animation: {
        'pulse-fast': 'wiggle 1s infinite;',
        'skeleton': 'skeleton 1s linear infinite alternate;',
      },
      keyframes: {
        wiggle: {
          '0%, 100%': { transform: 'scale(100%)' },
          '50%': { transform: 'scale(96%)' },
        },
        skeleton: {
          '0%': { opacity: 0.40 },
          '100%': { opacity: 0.60 },
        },
      },
      spacing: {
        '18': '4.5rem',
      }
    },
  },
  plugins: [],
};
