<template>
  <div v-click-away="onClickAway" class="dropdown">
    <div @click.stop="isMenuVisible = !isMenuVisible">
      <span class="sr-only">Open menu</span>
      <template v-if="type === 'icon'">
        <div class="text-lg cursor-pointer px-2">
          <font-awesome-icon :icon="icon"/>
        </div>
      </template>
      <template v-else>
        <button :class="customClasses" class="button">
          <font-awesome-icon v-if="iconLeft" :icon="iconLeft" fixed-width/>
          {{ text }}
          <font-awesome-icon v-if="iconRight" :icon="iconRight" fixed-width/>
        </button>
      </template>
    </div>

    <transition name="scale">
      <div v-show="isMenuVisible" class="absolute right-0 z-10">
        <div class="content">
          <div v-for="item in items" v-bind:key="item.index">
            <template v-if="item.divider">
              <div class="border-b border-neutral-200"></div>
            </template>
            <template v-else-if="item.show === undefined || item.show">
              <a :href="item.url ? item.url : null" class="menu-item" role="menuitem"
                 @click.stop="item.click ? item.click() : null">
                <span class="icon-text">
                  <span class="icon">
                    <font-awesome-icon :icon="item.icon" fixed-width/>
                  </span>
                  <span>{{ item.text }}</span>
                </span>
              </a>
            </template>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

export default {
  name: "DropDown",
  data() {
    return {
      isMenuVisible: false,
    }
  },
  props: {
    type: {
      Type: String,
      required: true,
    },
    customClasses: {
      Type: String,
      required: false,
    },
    text: {
      Type: String,
      required: false,
    },
    url: {
      Type: String,
      required: false,
    },
    icon: {
      Type: [Object, Array, String],
      required: false,
    },
    iconLeft: {
      Type: [Object, Array, String],
      required: false,
    },
    iconRight: {
      Type: [Object, Array, String],
      required: false,
    },
    items: {
      Type: Array,
      required: true,
    },
    click: {
      Type: Function,
      required: false,
    }
  },
  methods: {
    onClickAway() {
      this.isMenuVisible = false;
    }
  },
}
</script>

<style lang="scss" module>
</style>
