import { createStore } from 'vuex';
import router from '../router';

export default createStore({
  state() {
    return {
      token: localStorage.getItem('token'),
      onBoardingShown: localStorage.getItem('onBoardingShown'),
      player: {
        coins: 0,
        name: null,
        tag: null,
        activeTournament: null
      },
      leaderboard: null,
      tournamentsHistory: null,
      tournaments: null,
      tournament: null,
      tournamentRegister: 'unregistered',
      connectionStatus: 'connecting',
      serverTime: null,
      osPlayerId: null,
    };
  },
  getters: {
    getCoins(state) {
      if (!state.player) return 0;

      return state.player.coins;
    },
    getTag(state) {
      if (!state.player) return null;

      return state.player.id;
    },
    getName(state) {
      if (!state.player) return null;

      return state.player.name;
    },
    getOsPlayerId(state) {
      return state.osPlayerId;
    },
    getActiveTournament(state) {
      if (!state.player) return null;

      return state.player.activeTournament;
    },
    getTournaments(state) {
      return state.tournaments;
    },
    getTournament(state) {
      if (state.tournament && !state.tournament.playerCount) {
        state.tournament.playerCount = 0;
      }
      return state.tournament;
    },
    getLeaderboard(state) {
      return state.leaderboard;
    },
    getNextFreeCoins(state) {
      if (!state.player) return null;
      if (!state.player.nextFreeCoins) return new Date();

      return new Date(state.player.nextFreeCoins._seconds * 1000);
    },
    getTournamentsHistory(state) {
      if (state.tournamentsHistory) {
        return state.tournamentsHistory.sort((a, b) => {
          return a.updatedAt._seconds < b.updatedAt._seconds ? 1 : -1;
        });
      } else {
        return [];
      }
    },
    getRegisterTournamentStatus(state) {
      return state.tournamentRegister;
    },
    getOnBoardingShown(state) {
      return state.onBoardingShown;
    },
    getToken(state) {
      return state.token;
    },
    getIoServer() {
      return process.env.VUE_APP_SOCKET_IO_SERVER || 'https://royale-tournaments-realtime-z2msk5bu3q-uk.a.run.app';
    },
    getConnectionStatus(state) {
      return state.connectionStatus;
    },
    getServerTime(state) {
      // returning a function so it will be re-evaluated each call
      return function () {
        return new Date(state.serverTime.now()) || new Date();
      };
    }
  },
  mutations: {
    token: (state, token) => {
      if (token) {
        localStorage.setItem('token', token);
      } else {
        localStorage.removeItem('token');
        state.token = token;
      }
    },
    player: (state, player) => {
      state.player = player;
    },
    coinChange: (state, coinChange) => {
      state.player.coins = state.player.coins + coinChange;
    },
    activeTournament: (state, activeTournament) => {
      // we won the last tournament, let's show some cool stuff
      if (state.player && state.player.activeTournament && activeTournament.winner) {
        router.push({
          name: 'tournament-winner',
          params: { id: state.player.activeTournament },
          query: { prize: activeTournament.prize }
        });
      }

      if (state.player) {
        state.player.activeTournament = null;
      }
    },
    tournaments: (state, tournaments) => {
      state.tournaments = tournaments;
    },
    tournament: (state, tournament) => {
      state.tournament = tournament;
    },
    leaderboard: (state, leaderboard) => {
      state.leaderboard = leaderboard;
    },
    tournamentsHistory: (state, tournaments) => {
      state.tournamentsHistory = tournaments;
    },
    tournamentRegister: (state, status) => {
      if ((status.status === 'ok' || status.status === 'started') && status.tournamentId) {
        state.player.activeTournament = status.tournamentId;
      }

      if (status.status) {
        state.tournamentRegister = status.status;
      }
    },
    onBoardingShown: (state, status) => {
      state.onBoardingShown = status;
      localStorage.setItem('onBoardingShown', status);
    },
    osPlayerId: (state, osPlayerId) => {
      console.log('osPlayerId set', osPlayerId);
      state.osPlayerId = osPlayerId;
    },
    connectionStatus: (state, status) => {
      state.connectionStatus = status;
    },
    serverTime: (state, ts) => {
      state.serverTime = ts;
    }
  },
  actions: {},
  modules: {}
});
